<template>
  <div>
    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      title: 'Media Centers',
      resourceName: 'albums',
      singularName: 'album',
    }
  },
}
</script>
